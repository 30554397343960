
// Contact Disable Send Button
function toggleContactChecker() {
    var checkbox = document.getElementById('termsChkbx');
    var text = document.getElementById('termsText');
    var button = document.getElementById('submit');
  
    if (checkbox.checked) {
      text.classList.remove('text-initial');
      text.classList.add('text-active');
      button.classList.remove('button-initial');
      button.classList.add('button-active');
      button.disabled = false; // Enable the button
    } else {
      text.classList.remove('text-active');
      text.classList.add('text-initial');
      button.classList.remove('button-active');
      button.classList.add('button-initial');
      button.disabled = true; // Disable the button
    }
  }


// Navbar Script
document.addEventListener('DOMContentLoaded', function() {
  const menuOnButton = document.getElementById('menu-on');
  const menuOffButton = document.getElementById('menu-off');
  const menuBottom = document.querySelector('.menu-bottom');

  // Initially hide the menu-off button and menu content
  menuOffButton.classList.add('hidden');
  menuBottom.classList.remove('expanded');

  menuOnButton.addEventListener('click', function() {
      menuBottom.classList.add('expanded');
      menuOnButton.classList.add('hidden');
      menuOffButton.classList.remove('hidden');
      menuOnButton.classList.add('gray');
  });

  menuOffButton.addEventListener('click', function() {
      menuBottom.classList.remove('expanded');
      menuOnButton.classList.remove('hidden');
      menuOffButton.classList.add('hidden');
      menuOnButton.classList.remove('gray');
  });
});
